import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from 'app/shared/models/user.model';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html',
})
export class SidebarSideComponent implements OnInit {
  public user!: User;
  @Output() public clickItem = new EventEmitter();

  constructor(public jwtAuth: JwtAuthService) {}

  ngOnInit() {
    this.user = this.jwtAuth.getUser();
  }

  public onClickItem() {
    this.clickItem.emit();
  }
}
