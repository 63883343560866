<div class="sidenav-hold">
  <ul appDropdown class="sidenav">
    <li
      *ngFor="let item of sortBy('order')"
      appDropdownLink
      routerLinkActive="open">
      <div class="nav-item-sep" *ngIf="item.type === 'separator'">
        <mat-divider></mat-divider>
        <span class="text-muted">{{ item.name }}</span>
      </div>
      <div
        *ngIf="
          !item.disabled && item.type !== 'separator' && item.type !== 'icon'
        "
        class="lvl1 hover:bg-gray-100 {{
          isCurrentPath(item.state)
            ? 'border-l-[3px] border-blue-500 bg-gray-100'
            : ''
        }}">
        <a
          routerLink="/{{ item.state }}"
          (click)="onClickItem()"
          appDropdownToggle
          matRipple
          *ngIf="item.type === 'link'">
          <mat-icon>{{ item.icon }}</mat-icon>
          <span class="item-name lvl1">{{ item.name | translate }}</span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            [ngStyle]="{ background: badge.color }"
            *ngFor="let badge of item.badges">
            {{ badge.value }}
          </span>
        </a>
      </div>
    </li>
  </ul>
</div>
