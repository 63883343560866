import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { environment } from 'environments/environment';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-upload-manual-dialog',
  templateUrl: './upload-manual-dialog.component.html',
})
export class UploadManualDialogComponent {
  uploader: FileUploader;
  constructor(
    private readonly jwtAuth: JwtAuthService,
    private readonly snack: MatSnackBar,
    public dialogRef: MatDialogRef<UploadManualDialogComponent>
  ) {
    const token: unknown = this.jwtAuth.token || this.jwtAuth.getJwtToken();
    this.uploader = new FileUploader({
      url: environment.apiURL + '/api/v1/files/upload/manual',
      method: 'PUT',
      headers: [{ name: 'authorization', value: `${token}` }],
      maxFileSize: 1024 * 1024 * 25,
    });
    this.uploader.onBeforeUploadItem = item => {
      item.withCredentials = false;
    };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      if (status === 200) {
        this.snack.open('Manual subido correctamente', 'OK', {
          duration: 3000,
        });
        this.dialogRef.close();
        return;
      }

      this.snack.open('Error al subir el manual', 'OK', { duration: 3000 });
    };

    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      let message = '';
      switch (filter.name) {
        case 'fileSize':
          message =
            'Cuidado! \nEl archivo "' +
            item.name +
            '" es de ' +
            this.formatBytes(item.size) +
            ', excede el tamaño máximo permitido de ' +
            this.formatBytes(this.uploader.options.maxFileSize ?? 0);
          break;
        default:
          message = 'Error subiendo el archivo ' + item.name;
          break;
      }

      this.snack.open(message, 'OK', { duration: 3000 });
    };
  }

  private formatBytes(bytes: number, decimals?: number) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
