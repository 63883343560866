import { ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  Component,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'app/shared/models/user.model';
import { UploadService } from 'app/shared/services/upload.service';
import saveAs from 'file-saver';
import { FileUploader } from 'ng2-file-upload';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { UploadManualDialogComponent } from '../upload-manual-dialog/upload-manual-dialog.component';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',
  encapsulation: ViewEncapsulation.Emulated,
  styles: ``,
})
export class HeaderSideComponent implements OnInit {
  public user!: User;
  public uploader!: FileUploader;
  public opacity = 1;

  @Output() toggleSidenav = new EventEmitter();

  constructor(
    public jwtAuth: JwtAuthService,
    private readonly uploadService: UploadService,
    public uploadManualDialog: MatDialog,
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone
  ) {}
  ngOnInit() {
    this.user = this.jwtAuth.getUser();
  }

  onClickToggleSidenav() {
    this.toggleSidenav.emit();
  }

  downloadManual() {
    this.uploadService.downloadManual().subscribe(res => {
      const obj = res.body as {
        base64: string;
        fileName: string;
      };

      const blob = this.b64toBlob(obj.base64);

      saveAs(blob, obj.fileName);
    });
  }

  openUploadManualDialog() {
    this.uploadManualDialog.open(UploadManualDialogComponent, {
      width: '500px',
      disableClose: true,
    });
  }

  b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
