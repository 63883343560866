import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { FacturasyAbonosModel } from '../models/facturasyabonos.model';
import {
  OfertasDetalleArchivosModel,
  OfertasModel,
} from '../models/quotes/ofertas.model';
import { ApiHttpClient } from './api-http-client.service';
import { JwtAuthService } from './auth/jwt-auth.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private urlBase = environment.apiURL;
  private controlleName = 'UploadService';
  public urlDescargarArchivo = `/api/${this.controlleName}/GetFile`;
  constructor(
    private http: ApiHttpClient,
    private readonly jwtAuth: JwtAuthService
  ) {}

  downloadFile(archivo: OfertasDetalleArchivosModel) {
    const url = `/api/v1/files/download`;
    const headers = new HttpHeaders();

    return this.http.post(
      url,
      { fileName: archivo.nombreGuardado },
      { headers, responseType: 'blob' }
    );
  }

  downloadManual() {
    const url = `/api/v1/files/download/manual`;
    const headers = new HttpHeaders();

    return this.http.get(url, {
      headers,
      observe: 'response',
      responseType: 'json',
    });
  }

  descargarFactura(obj: FacturasyAbonosModel) {
    let url = '';

    //console.warn(obj);
    if (obj.tipo == 'INVOICE') {
      url = `/api/v1/files/download/invoice?id=${obj.documentNo}`;
    } else {
      url = `/api/v1/files/download/creditMemo?id=${obj.documentNo}`;
    }

    const headers = new HttpHeaders();

    return this.http.post(url, obj.documentNo, {
      headers,
      responseType: 'blob',
    });
  }

  descargarOferta(obj: OfertasModel) {
    let url = '';
    url = `/api/v1/files/download/quote?id=${obj.quoteNo}`;

    const headers = new HttpHeaders();

    return this.http.post(url, obj.quoteNo, { headers, responseType: 'blob' });
  }

  downlaodExcelFromJson(obj: any) {
    let url = '';
    url = `/api/v1/files/download/excel`;

    const headers = new HttpHeaders();

    return this.http.post(url, obj, { headers, responseType: 'blob' });
  }
}
