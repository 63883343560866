import { ScrollDispatcher, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { AvatarPhotoComponent } from './components/avatar-photo/avatar-photo.component';
import { HeaderSideComponent } from './components/header-side/header-side.component';
import { SidebarSideComponent } from './components/sidebar-side/sidebar-side.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { UploadManualDialogComponent } from './components/upload-manual-dialog/upload-manual-dialog.component';
import { LocaleCurrencyPipe } from './pipes/locale-currency.pipe';
import { LocaleDatePipe } from './pipes/locale-date.pipe';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { RoutePartsService } from './services/route-parts.service';
import { SharedMaterialModule } from './shared-material.module';

@NgModule({
  declarations: [
    SidebarSideComponent,
    HeaderSideComponent,
    SidenavComponent,
    AvatarPhotoComponent,
    UploadManualDialogComponent,
    LocaleCurrencyPipe,
    LocaleDatePipe,
  ],
  imports: [
    TranslateModule,
    RouterModule,
    FileUploadModule,
    CommonModule,
    SharedMaterialModule,
    FormsModule,
    MatIconModule,
    ScrollingModule,
  ],
  providers: [
    AppConfirmService,
    ScrollDispatcher,
    RoutePartsService,
    LocaleCurrencyPipe,
    LocaleDatePipe,
  ],
  exports: [
    SidebarSideComponent,
    HeaderSideComponent,
    SidenavComponent,
    AvatarPhotoComponent,
    UploadManualDialogComponent,
    LocaleCurrencyPipe,
    LocaleDatePipe,
  ],
})
export class SharedModule {}
