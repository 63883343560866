<h1 matDialogTitle>Subir manual</h1>
<mat-dialog-content>
  <button
    type="button"
    mat-stroked-button
    color="primary"
    title="Adjuntar Archivo"
    (click)="fileInput.click()">
    <mat-icon>attach_file</mat-icon>
    Seleccione un archivo
  </button>
  <input
    hidden
    type="file"
    #fileInput
    ng2FileSelect
    [uploader]="uploader"
    (change)="uploader.uploadAll()" />
  <div class="mt-8">
    <div
      *ngIf="uploader.progress !== 0 && uploader.progress !== 100"
      class="progress mb-1">
      <mat-progress-bar
        *ngIf="uploader.progress !== 0 && uploader.progress !== 100"
        color="primary"
        mode="determinate"
        [value]="uploader.progress">
      </mat-progress-bar>
      {{ uploader.progress }} %
    </div>
  </div>
  <div>
    <span fxFlex></span>
    <button
      mat-stroked-button
      color="primary"
      type="button"
      (click)="dialogRef.close()"
      [disabled]="uploader.progress !== 0 && uploader.progress !== 100">
      <mat-icon>close</mat-icon>
      Cancelar
    </button>
  </div>
</mat-dialog-content>
