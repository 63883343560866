import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-avatar-photo',
  templateUrl: './avatar-photo.component.html',
  styleUrls: ['./avatar-photo.component.less'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AvatarPhotoComponent {
  @Input()
  public name: string = '';
  public initials = '';

  public getInitials() {
    let initials = '';

    const words = this.name.split(' ');
    for (const word of words) {
      initials += word.charAt(0).toUpperCase();
      if (initials.length === 2) {
        break;
      }
    }

    return initials;
  }

  public getBackgroundColor() {
    return this.stringToHslColor(this.name, 50, 50);
  }

  private stringToHslColor(str: string, s: number, l: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }
}
