import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@shared/services/navigation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
  styleUrl: './sidenav.style.css',
  encapsulation: ViewEncapsulation.Emulated,
})
export class SidenavComponent implements OnInit, OnDestroy {
  public hasIconTypeMenuItem!: boolean;
  @Input() public hasIconMenu!: boolean;
  @Output() public clickItem = new EventEmitter();
  state = '';
  public menuItems: any[] = [];
  menuItemsSub!: Subscription;

  constructor(
    private readonly router: Router,
    private readonly navService: NavigationService
  ) {
    this.router.events.subscribe(() => {
      this.updateItemSelectState();
    });
  }

  updateItemSelectState() {
    if (!window.location.pathname.includes(this.state)) {
      this.state = '';
      return;
    }

    this.menuItems.forEach(item => {
      if (window.location.pathname.indexOf(item.state) > -1) {
        this.state = item.state;
      }
    });
  }

  ngOnInit() {
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        item => item.type === 'icon'
      ).length;

      this.updateItemSelectState();
    });
  }

  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }

  sortBy(prop: string) {
    return this.menuItems.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }

  isCurrentPath(state: string) {
    return this.state === state;
  }

  onClickItem() {
    this.clickItem.emit();
  }
}
