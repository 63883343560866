<mat-toolbar
  class="flex justify-between border-b-[1px] !bg-white sticky top-0 z-[1000]">
  <button
    mat-icon-button
    (click)="onClickToggleSidenav()"
    matTooltip="{{ 'HEADER.TOOLTIPS.BUTTON_TOGGLE_MENU' | translate }}"
    class="">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="flex">
    <button
      *ngIf="this.user.roleId !== 1"
      class="my-1 mr-3"
      mat-raised-button
      color="primary"
      routerLink="/solicitudes/editar/new"
      matTooltip="{{ 'HEADER.TOOLTIPS.BUTTON_NEW_REQUEST' | translate }}">
      <mat-icon class="transform scale-125">note_add</mat-icon>
      {{ 'HEADER.BUTTONS.NEW_REQUEST' | translate }}
    </button>

    <button
      class="my-1 mr-3"
      *ngIf="this.user.roleId === 1"
      type="button"
      mat-stroked-button
      color="primary"
      matTooltip="{{ 'HEADER.TOOLTIPS.BUTTON_UPLOAD_MANUAL' | translate }}"
      (click)="openUploadManualDialog()">
      <mat-icon>upload</mat-icon>
      {{ 'HEADER.BUTTONS.UPLOAD_MANUAL' | translate }}
    </button>

    <mat-divider vertical></mat-divider>

    <button
      matTooltip="{{ 'HEADER.TOOLTIPS.HELP_MENU' | translate }}"
      mat-icon-button
      [matMenuTriggerFor]="helpMenu"
      class="ml-3">
      <img
        src="assets/images/question-mark.png"
        alt=""
        class="transform scale-125" />
    </button>

    <mat-menu #helpMenu="matMenu">
      <button mat-menu-item (click)="downloadManual()">
        <mat-icon>file_download</mat-icon>
        <span> {{ 'HEADER.HELP_MENU.DOWNLOAD_MANUAL' | translate }} </span>
      </button>
    </mat-menu>

    <button
      class="!p-[7px]"
      mat-icon-button
      matTooltip="{{ 'HEADER.TOOLTIPS.USER_MENU' | translate }}"
      [matMenuTriggerFor]="accountMenu">
      <app-avatar-photo [name]="user.displayName ?? ''"></app-avatar-photo>
    </button>

    <mat-menu #accountMenu="matMenu">
      <span mat-menu-item disableRipple>
        <span>{{ this.user.displayName }}</span>
      </span>
      <button mat-menu-item [routerLink]="['/profile/settings']">
        <mat-icon>account_box</mat-icon>
        <span> {{ 'HEADER.USER_MENU.PROFILE' | translate }} </span>
      </button>
      <button mat-menu-item [routerLink]="['/sessions/change-password']">
        <mat-icon>vpn_key</mat-icon>
        <span>{{ 'HEADER.USER_MENU.CHANGE_PASSWORD' | translate }}</span>
      </button>
      <button mat-menu-item (click)="jwtAuth.signout()">
        <mat-icon>exit_to_app</mat-icon>
        <span> {{ 'HEADER.USER_MENU.SIGN_OUT' | translate }} </span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
