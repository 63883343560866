import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedCurrency',
})
export class LocaleCurrencyPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value: number | string,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null;
  transform(
    value: null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): null;
  transform(
    value: number | string | null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    const currencyPipe: CurrencyPipe = new CurrencyPipe(
      this.translateService.currentLang ?? this.translateService.defaultLang
    );
    return currencyPipe.transform(
      value,
      currencyCode,
      display,
      digitsInfo,
      locale
    );
  }
}
