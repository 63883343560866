<div>
  <div>
    <div>
      <div class="flex h-16 px-4 items-center border-b">
        <img
          class="max-w-64"
          *ngIf="user.company === 1"
          src="assets/images/illustrations/logo.jpg"
          alt="" />
        <img
          class="max-w-64"
          *ngIf="user.company === 2"
          src="assets/images/illustrations/servitec-logo.png"
          alt="" />
      </div>
      <app-sidenav (clickItem)="onClickItem()"></app-sidenav>
    </div>
  </div>
</div>
